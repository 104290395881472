.App {
  text-align: center;
}

.App-header {
  background-color: #ffc4d5;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  margin-top: 30px;
}

.App-link {
  color: #61dafb;
}

.couple h1 {
  margin-bottom: 50px;
}
.App-body {
  margin-top: 70px;
}
.meandhoney {
  display: flex;
  justify-content: center;
  align-items: center;
}
.all-video {
  display: flex;
  justify-content: center;
  align-items: center;
}
.all-video {
  display: flex;
  flex-wrap: wrap; 
  gap: 16px; 
}

.video-container {
  box-sizing: border-box; 
}
@media (max-width: 480px) {
 .meandhoney {
  display: block;
 }
 .video-container {
  position: relative;
  width: 100%; 
  padding-top: 56.25%; 
}

.video-container video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; 
}
.video-container {
  flex: 0 1 100%; 
}
}
.honey img,
.me img {
  width: 300px;
  height: 400px;
  border-radius: 20px;
}
.heart img {
  width: 200px;
  height: 200px;
  margin: 0px 30px;
}
.all-img {
  margin: 100px 0px;
}
.title-img-all {
  margin-bottom: 50px;
}
.emiu-content p,
.content p {
  font-size: 1.4rem;
  padding: 10px;
}
.emiu-content img {
  width: 280px;
  height: 280px;
  border-radius: 20px;
  padding: 10px;
}
.content img {
  width: 270px;
  height: 350px;
  border-radius: 20px;
  padding: 10px;
}
.xiang {
  margin: 50px;
}
.xiang em {
  font-size: 1.4rem;
}
.title-video {
  margin-top: 150px;
  margin-bottom: 50px;
}
.form-password input {
  width: 200px;
  height: 20px;
  outline: none;
  padding: 5px;
  border-radius: 10px;
  border: none;
}
.form-password button {
  height: 32px;
  width: 80px;
  cursor: pointer;
  background: #fa57ef;
  color: white;
  border: none;
  border-radius: 10px;
  margin-left: 5px;
}




.heart img {
  width: 200px; /* Kích thước ban đầu */
  animation: heartbeat 1s infinite; /* Hiệu ứng nhịp đập */
}

@keyframes heartbeat {
  0%, 100% {
    transform: scale(1); /* Kích thước ban đầu */
  }
  50% {
    transform: scale(1.2); /* Phồng lên */
  }
}
